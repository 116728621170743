import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string
  senha: string
  textoErro: string = ""

  constructor(public authService: AuthService) { }

  ngOnInit() {
  }

  async login() {
    var erro = await this.authService.login(this.email, this.senha)
    console.log(erro)
    if (erro.code == "auth/wrong-password") {
      this.textoErro = "Senha inválida"
    } else if (erro.code == "auth/too-many-requests") {
      this.textoErro = "Muitas tentativas, tente novamente mais tarde."
    } else if (erro.code == "auth/invalid-email") {
      this.textoErro = "E-mail inválido."
    } else {
      this.email = this.senha = ''
    }
  }


}

import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: firebase.User;
  id: string;
  nome: string;
  isAdmin: boolean;
  isCoord: boolean;
  isUser: boolean;
  isGraph: boolean;
  isGraphUser: boolean;
  level = 0;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,  
    public ngZone: NgZone,
    public afs: AngularFirestore
  ) {    
    
    this.afAuth.authState.subscribe(user => {
      if (user != null) {
        this.user = user
        this.id = user.uid
        this.nome = user.displayName
        user.getIdTokenResult().then(token => {
          this.isAdmin = token.claims.isAdmin
          this.isCoord = token.claims.isCoord
          this.isUser = token.claims.isUser
          this.isGraph = token.claims.isGraph
          this.isGraphUser = token.claims.isGraphUser
          this.level = token.claims.level

          /*
          console.log(this.id)
          console.log(this.nome)
          console.log(this.isAdmin)
          console.log(this.isCoord)
          console.log(this.isUser)
          console.log(this.isGraph)
          console.log(this.level)
          */

          if (["home"].indexOf(this.router.url.substr(1)) !== -1) {
            this.router.navigate(['main']);
          }
        })
      } else {
        this.router.navigate(['login']);
      }
    })
  }

  async getClaims() {
    await new Promise<string> (resolve => {
      this.afAuth.authState.subscribe(user => {
        this.user = user
        if (user != null) {
          user.getIdTokenResult().then(token => {
            resolve(this.id)
          })
        }
      })
    })
  }

  get isLoggedIn(): boolean {
    return this.user != undefined;
  }

  // Sign out 
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      this.user = null
      this.router.navigate(['login'])
    })
  }


  async login(email: string, password: string): Promise<any> {
    return await this.afAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        this.router.navigate(['main']);
        return ""
      })
      .catch(err => {
        //console.log('Something went wrong:',err.message);
        return err
      });
  }
}

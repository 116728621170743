import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { firestore } from 'firebase/app';
import { MatTableDataSource, MatSort, MatSortable, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  solicitacoesCol: AngularFirestoreCollection<any>
  solicitacoes: any
  dataSource = new MatTableDataSource()
  displayedColumns: string[] = [
    'id',
    'solicitante',
    'requisitante',
    'departamento',
    'disciplina',
    'unidade',
    'qtdpdf',
    'total',
    'criacao',
    'status',
  ]

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  
  constructor(
    public authService: AuthService,
    public db: AngularFirestore,
    public auth: AngularFireAuth,
    public router: Router,
  ) {
    authService.getClaims().then(u => {
      console.log(authService.id)
      this.loadDb()
    })
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
  }

  loadDb() {
    this.authService.getClaims().then(u => {

      if (this.authService.level > 0 || this.authService.isGraphUser) {
        this.solicitacoesCol = this.db.collection('solicitacoes', ref => ref
          .where("status","in",[0,50,75,90])
          .orderBy("criacao","desc")
        )
      }
  
      if (this.authService.isUser) {
        this.solicitacoesCol = this.db.collection('solicitacoes', ref => ref
          .where("status","in",[0,50,75,90])
          .where("usuariosIDs","array-contains",this.authService.id)
          .orderBy("criacao","desc")
        )
      }
  
      this.solicitacoes = this.solicitacoesCol.valueChanges({ idField: 'id' })
      .subscribe(data => {
        this.dataSource.data = data
      })

    })
  }

  status(qual) {
    if (qual == 0) return "Em fila"
    if (qual == 50) return "Em andamento"
    if (qual == 75) return "Aguardando resposta"
    if (qual == 90) return "Concluído"
    if (qual == 100) return "Entregue"
    if (qual == 150) return "Cancelado"
  }

  abrir(solicitacaoID) {
    this.goto('solicitacao/'+solicitacaoID)
  }

  goto(route) {
    this.router.navigate([route]);
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AuthService } from "../auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  NgForm,
} from "@angular/forms";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { FirebaseApp } from "@angular/fire";
import { storage } from "firebase/app";
import * as uuid from "uuid";
import { firestore } from "firebase/app";
import { Observable } from "rxjs";

@Component({
  selector: "app-nova",
  templateUrl: "./nova.component.html",
  styleUrls: ["./nova.component.scss"],
})
export class NovaComponent implements OnInit {
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;

  solicitacaoFormGroup: FormGroup;
  pdfSrc = [];
  totalPages = [];
  fileNames = [];
  usuarios = [];
  files = [];
  clicked = false;
  podeColorido = false;
  podeEncardenar = false;

  usuarioDoc: AngularFirestoreDocument<any>;
  usuario: Observable<any>;

  constructor(
    public db: AngularFirestore,
    public router: Router,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private firebaseApp: FirebaseApp
  ) {
    this.solicitacaoFormGroup = this.formBuilder.group({
      departamento: ["", Validators.required],
      requisitante: ["", Validators.required],
      disciplina: [""],
      unidade: ["", Validators.required],
      obs: [""],
      urgente: [false, Validators.required],
      pdfs: this.formBuilder.array([]),
    });

    authService.getClaims().then((u) => {
      if (authService.isGraph || authService.isGraphUser) this.goto("home");

      this.usuarioDoc = this.db.collection("usuarios").doc(authService.id);
      this.usuario = this.usuarioDoc.valueChanges();
      this.usuario.subscribe((doc) => {
        this.podeColorido = doc.colorido;
        this.podeEncardenar = doc.encadernado;

        if (authService.level > 0) {
          this.podeColorido = true;
          this.podeEncardenar = true;
        }
      });

      this.loadDb();
    });
  }

  ngOnInit() {}

  get pdfs(): FormArray {
    return this.solicitacaoFormGroup.get("pdfs") as FormArray;
  }

  newPDF(fileName: string): FormGroup {
    return this.formBuilder.group({
      fileName: [fileName, Validators.required],
      paginas: [
        "0",
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(5000),
        ]),
      ],
      copias: [
        "0",
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(5000),
        ]),
      ],
      encadernacao: ["0", Validators.required],
      grampear: [false, Validators.required],
      frenteverso: [false, Validators.required],
      colorida: [false, Validators.required],
    });
  }

  changeEncad(ob) {
    console.log(ob);
    this.pdfs.controls.forEach((control) => {
      if (control.get("encadernacao").value != "0") {
        control.get("grampear").disable();
        control.get("grampear").patchValue(false);
      } else {
        control.get("grampear").enable();
      }
    });
  }

  loadDb() {
    if (this.authService.isUser) {
      let u = {
        id: this.authService.id,
        nome: this.authService.nome,
      };
      this.usuarios.push(u);
      this.solicitacaoFormGroup.patchValue({
        requisitante: this.authService.id + "|" + this.authService.nome,
      });
    } else {
      this.db
        .collection("usuarios")
        .ref.orderBy("nome")
        .get()
        .then((col) => {
          col.forEach((doc) => {
            let d = { ...doc.data() };
            d.id = doc.id;
            this.usuarios.push(d);
          });
          this.solicitacaoFormGroup.patchValue({
            requisitante: this.authService.id + "|" + this.authService.nome,
          });
        });
    }
  }

  goto(route) {
    this.router.navigate([route]);
  }

  onFileSelected() {
    let $img: any = document.querySelector("#file");

    if (typeof FileReader !== "undefined") {
      let reader = new FileReader();

      reader.onload = (e: any) => {
        console.log(e);

        this.pdfSrc.push(e.target.result);
      };

      reader.readAsArrayBuffer($img.files[0]);
      this.fileNames.push($img.files[0].name);
      this.pdfs.push(this.newPDF($img.files[0].name));
      this.files.push($img.files[0]);
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages.push({ paginas: pdf.numPages });
    this.pdfs.patchValue(this.totalPages);
  }

  onSubmit(formDirective: NgForm) {
    console.log(this.solicitacaoFormGroup.value);
    const this_ = this;

    if (this.solicitacaoFormGroup.valid && this.files.length > 0) {
      this.clicked = true;

      this.files.forEach((file) => {
        var fullname = file.name;
        const fname = uuid.v4();
        const fext = fullname
          .slice(((fullname.lastIndexOf(".") - 1) >>> 0) + 2)
          .toLowerCase();
        var storageRef = this.firebaseApp
          .storage()
          .ref("documentos/" + fname + "." + fext);
        var uploadTask = storageRef.put(file);

        uploadTask.on(
          storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (uploadTask.snapshot.bytesTransferred /
                uploadTask.snapshot.totalBytes) *
              100;
            //this.imgPct.nativeElement.innerHTML = Math.ceil(progress) + '%';
          },
          function (error) {
            console.log(error.message);
          },
          function () {
            //Pega a URL da imagem
            storageRef.getDownloadURL().then(function (url) {
              const dados = { ...this_.solicitacaoFormGroup.value };

              if (!this_.podeColorido)
                dados.obs =
                  dados.obs +
                  " - Usuário sem autorização para impressão Colorida - imprimir apenas Preto/Branco";

              dados.criacao = firestore.FieldValue.serverTimestamp();
              dados.total = 0;
              dados.totalpbfrente = 0;
              dados.totalpbfrenteverso = 0;
              dados.totalcorfrente = 0;
              dados.totalcorfrenteverso = 0;
              dados.totalenc = 0;
              dados.totalgramp = 0;

              dados.pdfs.forEach((pdf, index) => {
                dados.pdfs[index].copias = parseInt(dados.pdfs[index].copias);
                dados.pdfs[index].total =
                  dados.pdfs[index].copias * dados.pdfs[index].paginas;

                if (
                  !dados.pdfs[index].colorida &&
                  !dados.pdfs[index].frenteverso
                )
                  dados.totalpbfrente =
                    dados.totalpbfrente + dados.pdfs[index].total;
                if (
                  !dados.pdfs[index].colorida &&
                  dados.pdfs[index].frenteverso
                )
                  dados.totalpbfrenteverso =
                    dados.totalpbfrenteverso + dados.pdfs[index].total;
                if (
                  dados.pdfs[index].colorida &&
                  !dados.pdfs[index].frenteverso
                )
                  dados.totalcorfrente =
                    dados.totalcorfrente + dados.pdfs[index].total;
                if (dados.pdfs[index].colorida && dados.pdfs[index].frenteverso)
                  dados.totalcorfrenteverso =
                    dados.totalcorfrenteverso + dados.pdfs[index].total;

                if (parseInt(dados.pdfs[index].encadernacao) == 1)
                  dados.totalenc += dados.pdfs[index].copias;
                if (dados.pdfs[index].grampear)
                  dados.totalgramp += dados.pdfs[index].total;

                dados.total += dados.pdfs[index].total;

                if (pdf.fileName == fullname) {
                  dados.pdfs[index].newName = fname + "." + fext;
                  dados.pdfs[index].url = url;
                }

                dados.pdfs[index].status = 0;
                dados.status = 0;
                console.log(this_.authService.id);
                console.log(this_.authService.nome);
                dados.solicitante = this_.authService.nome;
                dados.solicitanteID = this_.authService.id;
              });

              let ultimo = true;

              dados.pdfs.forEach((pdfCheck) => {
                if (pdfCheck.url == undefined) {
                  ultimo = false;
                }
              });

              if (ultimo) {
                this_.db
                  .collection("solicitacoes")
                  .add(dados)
                  .then((sol) => {
                    this_.goto("home");
                  });
              }
            });
          }
        );
      });
    }
  }
}

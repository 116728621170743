import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'copiadora';

  events: string[] = []
  opened: boolean
  displayName = ""
  
  constructor(public authService: AuthService, public router: Router, public afAuth: AngularFireAuth) {}

  ngOnInit() {
    this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

    this.authService.getClaims().then(v => {
      this.displayName = this.authService.nome
    })

  }

  goto(route) {
    this.router.navigate([route]);
  }

  logout() {
    this.authService.SignOut()
  }

  checkpage() {
    var pode = true;
    if (["/login",].includes(this.router.url)) pode = false;

    return pode;
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { AuthService } from "../auth.service";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { firestore } from "firebase/app";
import { AngularFireFunctions } from "@angular/fire/functions";
import { MatTableDataSource, MatSlideToggleChange } from "@angular/material";
import { Router } from "@angular/router";
import { ConfirmComponent } from "../confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";

export const niveisUsuarios = {
  admin: { tipo: "admin", level: 10, titulo: "Administrador" },
  coord: { tipo: "coord", level: 5, titulo: "Gestor Acadêmico" },
  user: { tipo: "user", level: 0, titulo: "Assistente de Impressão" },
  graph: { tipo: "graph", level: 10, titulo: "Adm Gráfica" },
  graphuser: { tipo: "graphuser", level: 0, titulo: "Usuário Gráfica" },
};

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.scss"],
})
export class UsuariosComponent implements OnInit {
  displayedColumns: string[] = [
    "nome",
    "email",
    "tipo",
    "colorido",
    "encadernado",
    "ativo",
    "opcoes",
  ];
  objectKeys = Object.keys;
  niveis = {};
  usuarioFormGroup: FormGroup;
  clicked = false;
  niveisPossiveis = {
    user: { tipo: "user", level: 0, titulo: "Assistente de Impressão" },
  };

  usuariosCol: AngularFirestoreCollection<any>;
  usuarios: any;
  dataSource = new MatTableDataSource();
  selectedUser = "";

  visibilidadeDesativados = false;

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public db: AngularFirestore,
    public auth: AngularFireAuth,
    public router: Router,
    public functions: AngularFireFunctions,
    public dialog: MatDialog
  ) {
    authService.getClaims().then((u) => {
      if (authService.isGraph || authService.isGraphUser || authService.isUser)
        this.goto("home");
      if (authService.isAdmin) this.niveisPossiveis = niveisUsuarios;
    });
  }

  ngOnInit() {
    this.niveis = niveisUsuarios;

    this.usuarioFormGroup = this.formBuilder.group(
      {
        nome: ["", Validators.required],
        email: ["", Validators.required],
        tipo: ["", Validators.required],
        senha: [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(30),
          ]),
        ],
        confirma: [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(30),
          ]),
        ],
        colorido: [false, Validators.required],
        encadernado: [false, Validators.required],
      },
      {
        validators: this.password.bind(this),
      }
    );

    this.loadDb();
  }

  editUser(user: { [key: string]: any }) {
    this.usuarioFormGroup.patchValue(user);
    this.selectedUser = user.id;
    // this.usuarioFormGroup.controls["senha"].disable();
    // this.usuarioFormGroup.controls["confirma"].disable();
    this.usuarioFormGroup.controls["email"].disable();
    this.usuarioFormGroup.controls["colorido"].disable();
    this.usuarioFormGroup.controls["encadernado"].disable();
    document.getElementById("insertButton").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  loadDb() {
    this.usuariosCol = this.db.collection("usuarios", (ref) =>
      ref.orderBy("tipo").orderBy("nome")
    );
    const _this = this;
    this.usuarios = this.usuariosCol
      .valueChanges({ idField: "id" })
      .subscribe((data) => {
        // this.dataSource.data = data.filter((doc) => doc.ativo);
        this.dataSource.data = _this.visibilidadeDesativados
          ? data.sort((a, b) => a.nome.localeCompare(b.nome))
          : data
              .filter((doc) => doc.ativo)
              .sort((a, b) => a.nome.localeCompare(b.nome));
      });
  }

  onSubmit(formDirective: NgForm) {
    if (
      this.selectedUser != "" &&
      this.usuarioFormGroup.controls["nome"].valid
    ) {
      this.db.collection("usuarios").doc(this.selectedUser).update({
        nome: this.usuarioFormGroup.controls["nome"].value,
        tipo: this.usuarioFormGroup.controls["tipo"].value,
      });

      const updateClaims = this.functions.httpsCallable("updateClaims");
      const claims = {
        auth_id: this.selectedUser,
        tipo: this.usuarioFormGroup.controls["tipo"].value,
        nome: this.usuarioFormGroup.controls["nome"].value,
        senha: this.usuarioFormGroup.controls["senha"].value,
      };
      console.log("claims", claims);

      updateClaims(claims).subscribe((response) => {
        console.log("update");
        console.log(response);
        this.clicked = false;
        formDirective.resetForm();
        this.ngOnInit();
      });
    } else {
      if (this.usuarioFormGroup.valid) {
        this.clicked = true;

        let dados = Object.assign(this.usuarioFormGroup.value);
        dados.criacao = firestore.FieldValue.serverTimestamp();
        dados.ativo = true;

        const senha: string = dados.senha;

        delete dados.senha;
        delete dados.confirma;

        console.log(dados.email);
        console.log(senha);

        const createUser = this.functions.httpsCallable("createUser");
        createUser({
          email: dados.email,
          senha: senha,
        }).subscribe((response) => {
          if (response !== false) {
            this.db
              .collection("usuarios")
              .doc(response)
              .set(dados)
              .catch((err) => {
                console.log(err);
              });

            const updateClaims = this.functions.httpsCallable("updateClaims");
            updateClaims({
              auth_id: response,
              tipo: dados.tipo,
              nome: dados.nome,
            }).subscribe((response) => {
              console.log("fim");
              console.log(response);
              this.clicked = false;
              formDirective.resetForm();
              this.ngOnInit();
            });
          }
        });
      }
    }
  }

  password(formGroup: FormGroup) {
    const { value: senha } = formGroup.get("senha");
    const { value: confirma } = formGroup.get("confirma");
    return senha === confirma ? null : { passwordNotMatch: true };
  }

  goto(route) {
    this.router.navigate([route]);
  }

  updateTogle(usuarioID: string, valor: MatSlideToggleChange, qual: number) {
    let dados: {};
    dados = { colorido: valor.checked };

    if (qual == 1) dados = { encadernado: valor.checked };

    this.db.collection("usuarios").doc(usuarioID).update(dados);
  }

  updateAtivo(usuarioID: string, valor: MatSlideToggleChange) {
    this.db
      .collection("usuarios")
      .doc(usuarioID)
      .update({ ativo: valor.checked });
  }

  resetPass(usuario) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: "250px",
      data:
        "Confirma enviar e-mail de nova senha para o usuario " +
        usuario.nome +
        "?",
    });

    dialogRef.afterClosed().subscribe((confirma) => {
      if (confirma) {
        this.auth.auth.sendPasswordResetEmail(usuario.email);
      }
    });
  }

  confirm(msg: string, numPDF: number, status: number): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: "250px",
      data: msg,
    });

    dialogRef.afterClosed().subscribe((confirma) => {
      if (confirma) {
      }
    });
  }

  toggleVisibilidade() {
    this.visibilidadeDesativados = !this.visibilidadeDesativados;
    this.loadDb();
  }

  /*
  mudaTipo(usuario) {
    const updateClaims = this.functions.httpsCallable('updateClaims')
    updateClaims({
      tipo: "admin",
      nome: usuario.nome,
      auth_id:
    }).subscribe(response => {
      if (response !== false) {
        this.db.collection("usuarios").doc(response).set(dados)
        .catch(err => {console.log(err)})

        const updateClaims = this.functions.httpsCallable('updateClaims')
        updateClaims({
          auth_id: response,
          tipo: dados.tipo,
          nome: dados.nome,
        }).subscribe(response => {
          console.log("fim")
          console.log(response)
          this.clicked = false
          formDirective.resetForm()
          this.ngOnInit()
        })
      }
    })
  }
  */
}

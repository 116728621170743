import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { AuthService } from "../auth.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { ConfirmComponent } from "../confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { MensagemComponent } from "../mensagem/mensagem.component";
import * as uuid from "uuid";
import { FirebaseApp } from "@angular/fire";
import { firestore, storage } from "firebase/app";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PDFDocumentProxy } from "ng2-pdf-viewer";

export interface PDF {
  colorida: boolean;
  copias: number;
  encadernacao: string;
  fileName: string;
  frenteverso: boolean;
  grampear: boolean;
  newName: string;
  url: string;
  paginas: number;
  status: number;
  total: number;
  responsavel: string;
  responsavelID: string;
  mensagem: string;
}

export interface Solicitacao {
  criacao: Date;
  departamento: string;
  disciplina: string;
  numero: number;
  obs: string;
  requisitante: string;
  solicitante: string;
  solicitanteID: string;
  unidade: string;
  urgente: boolean;
  discistatusplina: number;
  total: number;
  totalcorfrente: number;
  totalcorfrenteverso: number;
  totalenc: number;
  totalgramp: number;
  totalpbfrente: number;
  totalpbfrenteverso: number;
  pdfs: PDF[];
  status: number;
  fim: firestore.FieldValue;
}

@Component({
  selector: "app-solicitacao",
  templateUrl: "./solicitacao.component.html",
  styleUrls: ["./solicitacao.component.scss"],
})
export class SolicitacaoComponent implements OnInit {
  solicitacaoID: string = "";
  solicitacaoDoc: AngularFirestoreDocument<Solicitacao>;
  solicitacao: Observable<Solicitacao>;
  solicitacaoData: Solicitacao;
  historicoCol: AngularFirestoreCollection<any>;
  historico: Observable<any>;
  qualSub = -1;
  newPdf;
  pdfFile;
  clicked = false;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;

  constructor(
    public authService: AuthService,
    public db: AngularFirestore,
    public router: Router,
    private route: ActivatedRoute,
    private storage: AngularFireStorage,
    public dialog: MatDialog,
    private firebaseApp: FirebaseApp,
    private matSnackBar: MatSnackBar
  ) {
    this.solicitacaoID = this.route.snapshot.params.option;
    if (this.solicitacaoID == undefined) this.goto("home");

    this.solicitacaoDoc = this.db
      .collection("solicitacoes")
      .doc(this.solicitacaoID);
    this.solicitacao = this.solicitacaoDoc.valueChanges();
    this.solicitacao.subscribe((data) => {
      this.solicitacaoData = data;
    });

    this.historicoCol = this.db.collection(
      "solicitacoes/" + this.solicitacaoID + "/historico",
      (ref) => ref.orderBy("quando")
    );
    this.historico = this.historicoCol.valueChanges({ idField: "id" });
  }

  ngOnInit() {}

  download(pdf) {
    window.open(pdf, "_blank");
  }

  goto(route) {
    this.router.navigate([route]);
  }

  status(qual) {
    if (qual == 0) return "Em fila";
    if (qual == 50) return "Em andamento";
    if (qual == 75) return "Aguardando resposta";
    if (qual == 90) return "Concluído";
    if (qual == 100) return "Entregue";
    if (qual == 150) return "Cancelado";
  }

  confirm(msg: string, numPDF: number, status: number): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: "250px",
      data: msg,
    });

    dialogRef.afterClosed().subscribe((confirma) => {
      if (confirma) {
        this.solicitacaoData.pdfs[numPDF].responsavel = this.authService.nome;
        this.solicitacaoData.pdfs[numPDF].responsavelID = this.authService.id;

        //Reativa
        if (status == 0) {
          this.solicitacaoData.pdfs[numPDF].status = 0;

          //Verifica se precisa reativar a solicitação
          if (this.solicitacaoData.status != 150)
            this.solicitacaoData.status = 0;

          this.db
            .collection("solicitacoes")
            .doc(this.solicitacaoID)
            .update(this.solicitacaoData);
          return true;
        }

        //Início da cópia
        if (status == 50) {
          this.solicitacaoData.status = 50;
          this.solicitacaoData.pdfs[numPDF].status = 50;

          this.db
            .collection("solicitacoes")
            .doc(this.solicitacaoID)
            .update(this.solicitacaoData);
          return true;
        }

        //Fim da cópia
        if (status == 90) {
          this.solicitacaoData.pdfs[numPDF].status = 90;

          //Se acabou tudo finaliza o pedido
          let fim = true;
          this.solicitacaoData.pdfs.forEach((pdf) => {
            if (pdf.status < 90) fim = false;
          });
          if (fim) this.solicitacaoData.status = 90;

          this.db
            .collection("solicitacoes")
            .doc(this.solicitacaoID)
            .update(this.solicitacaoData);
          return true;
        }

        //Fim da cópia
        if (status == 100) {
          this.solicitacaoData.pdfs[numPDF].status = 100;

          //Se acabou tudo finaliza o pedido
          let fim = true;
          this.solicitacaoData.pdfs.forEach((pdf) => {
            if (pdf.status < 100) fim = false;
          });
          if (fim) {
            this.solicitacaoData.status = 100;
            this.solicitacaoData.fim = firestore.FieldValue.serverTimestamp();
          }

          this.db
            .collection("solicitacoes")
            .doc(this.solicitacaoID)
            .update(this.solicitacaoData);
          return true;
        }

        //Cancela da cópia
        if (status == 150) {
          this.solicitacaoData.pdfs[numPDF].status = 150;

          //Se acabou tudo finaliza o pedido
          let fim = true;
          this.solicitacaoData.pdfs.forEach((pdf) => {
            if (pdf.status < 100) fim = false;
          });
          if (fim) {
            this.solicitacaoData.status = 100;
            this.solicitacaoData.fim = firestore.FieldValue.serverTimestamp();
          }

          //Verifica se cancelou tudo
          fim = true;
          this.solicitacaoData.pdfs.forEach((pdf) => {
            if (pdf.status !== 150) fim = false;
          });
          if (fim) {
            this.solicitacaoData.status = 150;
            this.solicitacaoData.fim = firestore.FieldValue.serverTimestamp();
          }

          this.db
            .collection("solicitacoes")
            .doc(this.solicitacaoID)
            .update(this.solicitacaoData);
          return true;
        }
      }
    });
  }

  message(numPDF: number): void {
    const dialogRef = this.dialog.open(MensagemComponent, {
      width: "350px",
    });

    dialogRef.afterClosed().subscribe((mensagem) => {
      if (mensagem !== undefined && mensagem !== "") {
        this.solicitacaoData.pdfs[numPDF].responsavel = this.authService.nome;
        this.solicitacaoData.pdfs[numPDF].responsavelID = this.authService.id;
        this.solicitacaoData.pdfs[numPDF].mensagem = mensagem;

        this.solicitacaoData.status = 75;
        this.solicitacaoData.pdfs[numPDF].status = 75;

        this.db
          .collection("solicitacoes")
          .doc(this.solicitacaoID)
          .update(this.solicitacaoData);
        this.db
          .collection("solicitacoes")
          .doc(this.solicitacaoID)
          .update({ status: 75 });
      }
    });
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    if (this.qualSub > -1) {
      let dados = { ...this.solicitacaoData };

      dados.pdfs[this.qualSub].paginas = pdf.numPages;
      const file = this.pdfFile;

      const this_ = this;

      var fullname = file.name;
      const fname = uuid.v4();
      const fext = fullname
        .slice(((fullname.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();
      var storageRef = this.firebaseApp
        .storage()
        .ref("documentos/" + fname + "." + fext);
      var uploadTask = storageRef.put(file);

      uploadTask.on(
        storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var progress =
            (uploadTask.snapshot.bytesTransferred /
              uploadTask.snapshot.totalBytes) *
            100;
          //this.imgPct.nativeElement.innerHTML = Math.ceil(progress) + '%';
        },
        function (error) {
          console.log(error.message);
        },
        function () {
          //Pega a URL da imagem
          storageRef.getDownloadURL().then(function (url) {
            console.log(url);

            dados.pdfs[this_.qualSub].url = url;
            dados.total = 0;
            dados.totalpbfrente = 0;
            dados.totalpbfrenteverso = 0;
            dados.totalcorfrente = 0;
            dados.totalcorfrenteverso = 0;
            dados.totalenc = 0;
            dados.totalgramp = 0;

            dados.pdfs.forEach((pdf, index) => {
              dados.pdfs[index].total =
                dados.pdfs[index].copias * dados.pdfs[index].paginas;

              if (!dados.pdfs[index].colorida && !dados.pdfs[index].frenteverso)
                dados.totalpbfrente =
                  dados.totalpbfrente + dados.pdfs[index].total;
              if (!dados.pdfs[index].colorida && dados.pdfs[index].frenteverso)
                dados.totalpbfrenteverso =
                  dados.totalpbfrenteverso + dados.pdfs[index].total;
              if (dados.pdfs[index].colorida && !dados.pdfs[index].frenteverso)
                dados.totalcorfrente =
                  dados.totalcorfrente + dados.pdfs[index].total;
              if (dados.pdfs[index].colorida && dados.pdfs[index].frenteverso)
                dados.totalcorfrenteverso =
                  dados.totalcorfrenteverso + dados.pdfs[index].total;

              if (parseInt(dados.pdfs[index].encadernacao) == 1)
                dados.totalenc += dados.pdfs[index].total;
              if (dados.pdfs[index].grampear)
                dados.totalgramp += dados.pdfs[index].total;

              dados.total += dados.pdfs[index].total;

              if (pdf.fileName == fullname) {
                dados.pdfs[index].newName = fname + "." + fext;
                dados.pdfs[index].url = url;
              }
            });
            console.log(dados);

            this_.db
              .doc("solicitacoes/" + this_.solicitacaoID)
              .update(dados)
              .then((sol) => {
                //this_.goto('home')
                this_.qualSub = -1;
                this_.clicked = false;
              });
          });
        }
      );
    }
  }

  onFileSelected() {
    this.clicked = true;

    let $img: any = document.querySelector("#file");

    if (typeof FileReader !== "undefined") {
      let reader = new FileReader();

      reader.onload = (e: any) => {
        this.newPdf = e.target.result;
      };

      reader.readAsArrayBuffer($img.files[0]);
      this.pdfFile = $img.files[0];
    }
  }

  substituir(qual) {
    this.qualSub = qual;
    this.fileUpload.nativeElement.click();
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "../auth.service";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import {
  MatTableDataSource,
  MatSort,
  MatSortable,
  MatPaginator,
} from "@angular/material";
import { Router } from "@angular/router";
import * as XLSX from "xlsx";

@Component({
  selector: "app-historico",
  templateUrl: "./historico.component.html",
  styleUrls: ["./historico.component.scss"],
})
export class HistoricoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("TABLE", { static: true }) table: ElementRef;

  solicitacoesCol: AngularFirestoreCollection<any>;
  solicitacoes: any;
  usuarios = [];
  meses = [];
  inicio = new Date();
  fim = new Date();
  mes = "02|2020";
  requisitante = "T|Todos";
  unidade = "Todas";
  departamento = "Todos";
  statusFiltro = "Todos";
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    "id",
    "solicitante",
    "requisitante",
    "unidade",
    "departamento",
    "qtdpdf",
    "total",
    "pbf",
    "pbfv",
    "cf",
    "cfv",
    "enc",
    "gramp",
    "criacao",
    "fim",
    "status",
  ];
  total = 0;
  totalpbfrente = 0;
  totalpbfrenteverso = 0;
  totalcorfrente = 0;
  totalcorfrenteverso = 0;
  totalenc = 0;
  totalgramp = 0;
  totalsolicit = 0;

  constructor(
    public authService: AuthService,
    public db: AngularFirestore,
    public router: Router
  ) {
    authService.getClaims().then((u) => {
      console.log(authService.id);
      this.loadDb();

      if (this.authService.isUser) {
        let u = {
          id: this.authService.id,
          nome: this.authService.nome,
        };
        this.usuarios.push(u);
      } else {
        if (!this.authService.isGraphUser) {
          this.db
            .collection("usuarios")
            .ref.orderBy("nome")
            .get()
            .then((col) => {
              this.usuarios.push({ id: "T", nome: "Todos" });
              this.requisitante = "T|Todos";
              col.forEach((doc) => {
                let d = { ...doc.data() };
                d.id = doc.id;
                if (!["graph", "graphuser"].includes(d.tipo))
                  this.usuarios.push(d);
              });
            });
        }
      }
    });
  }

  ngOnInit() {
    this.inicio.setDate(1);
    this.inicio.setHours(0);
    this.inicio.setMinutes(0);
    this.fim.setDate(1);
    this.fim.setMonth(this.fim.getMonth() + 1);
    this.fim.setDate(0);
    this.fim.setHours(23);
    this.fim.setMinutes(59);

    var hoje = new Date();
    hoje.setDate(1);
    this.mes =
      ("0" + (hoje.getMonth() + 1)).slice(-2) + "|" + hoje.getFullYear();
    do {
      this.meses.push({
        ano: hoje.getFullYear(),
        mes: ("0" + (hoje.getMonth() + 1)).slice(-2),
      });

      var mes = hoje.getMonth();
      hoje.setMonth(hoje.getMonth() - 1);
      if (mes == hoje.getMonth()) hoje.setMonth(hoje.getMonth() - 1);
    } while (hoje > new Date("2020-03-01 00:00:00"));

    this.dataSource.paginator = this.paginator;

    this.loadDb();
  }

  update() {
    this.loadDb();
  }

  ngOnDestroy() {
    this.solicitacoes.unsubscribe();
  }

  loadDb() {
    let qualUsuario = this.requisitante.split("|")[0];
    let qualUnidade = this.unidade;
    let qualDepartamento = this.departamento;
    let qualStatus = this.statusFiltro;
    let dataBusca = "criacao";

    if ([100, 150].includes(parseInt(this.statusFiltro))) {
      dataBusca = "fim";
    }

    let newMes = this.mes.split("|")[0];
    let newAno = this.mes.split("|")[1];

    this.inicio.setFullYear(parseInt(newAno));
    this.inicio.setMonth(parseInt(newMes) - 1);

    this.fim.setDate(1);
    this.fim.setFullYear(parseInt(newAno));
    this.fim.setMonth(parseInt(newMes));
    this.fim.setDate(0);

    if (this.authService.level > 0 || this.authService.isGraphUser) {
      this.solicitacoesCol = this.db.collection("solicitacoes", (ref) => {
        var newRef = ref
          .where(dataBusca, ">=", this.inicio)
          .where(dataBusca, "<=", this.fim);

        if (this.requisitante != "T|Todos")
          newRef = newRef.where("usuariosIDs", "array-contains", qualUsuario);
        if (this.unidade != "Todas")
          newRef = newRef.where("unidade", "==", qualUnidade);
        if (this.departamento != "Todos")
          newRef = newRef.where("departamento", "==", qualDepartamento);
        if (this.statusFiltro != "Todos")
          newRef = newRef.where("status", "==", parseInt(qualStatus));

        newRef = newRef.orderBy(dataBusca);

        return newRef;
      });
    }

    if (this.authService.isUser) {
      this.solicitacoesCol = this.db.collection("solicitacoes", (ref) =>
        ref
          .where(dataBusca, ">=", this.inicio)
          .where(dataBusca, "<=", this.fim)
          .where("usuariosIDs", "array-contains", this.authService.id)
          .orderBy(dataBusca)
      );
    }

    if (this.solicitacoesCol)
      this.solicitacoes = this.solicitacoesCol
        .valueChanges({ idField: "id" })
        .subscribe((data) => {
          this.dataSource.data = data;
          this.totalsolicit = this.dataSource.data.length;

          this.total = 0;
          this.totalpbfrente = 0;
          this.totalpbfrenteverso = 0;
          this.totalcorfrente = 0;
          this.totalcorfrenteverso = 0;
          this.totalenc = 0;
          this.totalgramp = 0;

          data.forEach((doc) => {
            this.total = this.total + doc.total;
            this.totalpbfrente = this.totalpbfrente + doc.totalpbfrente;
            this.totalpbfrenteverso =
              this.totalpbfrenteverso + doc.totalpbfrenteverso;
            this.totalcorfrente = this.totalcorfrente + doc.totalcorfrente;
            this.totalcorfrenteverso =
              this.totalcorfrenteverso + doc.totalcorfrenteverso;
            this.totalenc = this.totalenc + doc.totalenc;
            this.totalgramp = this.totalgramp + doc.totalgramp;
          });
        });
    /*
    this.db
      .collection("solicitacoes")
      .ref.where("totalenc", ">", 0)
      .get()
      .then((col) => {
        col.forEach((doc) => {
          console.log(doc.id);
          console.log(doc.data().totalenc);
        });
      });
      */
  }

  status(qual) {
    if (qual == 0) return "Em fila";
    if (qual == 50) return "Em andamento";
    if (qual == 75) return "Aguardando resposta";
    if (qual == 90) return "Concluído";
    if (qual == 100) return "Entregue";
    if (qual == 150) return "Cancelado";
  }

  abrir(solicitacaoID) {
    this.goto("solicitacao/" + solicitacaoID);
  }

  goto(route) {
    this.router.navigate([route]);
  }

  exportAsExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Histórico");

    /* save to file */
    XLSX.writeFile(wb, "historico.xlsx");
  }
}
